.screen-shots {
    font-family: $font-family;

    h3 {
        margin-bottom: 10px;
    }

    img {
        margin-right: 10px;
        margin-bottom: 15px;
    }
}