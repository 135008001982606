.version-history {
    ul {
        list-style-type: none;
        padding        : 0;
        margin         : 0;
        margin-bottom  : 15px;

        li {
            font-family: $font-family;
            font-size  : 0.8em;
            padding    : 1px 0;

            &.version {
                font-weight: bold;
                font-size  : 0.9em;
            }
        }
    }
}