.about {
    font-family: $font-family;

    a {
        color          : $color-dropf-dark;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    ul {
        margin         : 0;
        padding        : 0;
        list-style-type: none;
        font-size      : 0.9em;
        color          : #333;

        li {
            padding-bottom: 5px;
            margin-bottom : 5px;
            border-bottom : 1px dotted #ccc;

            ul {
                list-style-type: square;
                padding-left   : 17px;

                li {
                    padding      : 1px 0;
                    margin-bottom: 0;
                    border-bottom: none;
                }
            }
        }
    }
}